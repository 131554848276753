
















































































































































































































































































































































































































































import DialogMixins from '@/mixins/DialogMixins';
import WidgetMixins from '@/mixins/WidgetMixins';
import { loadWidget, truncateContent } from '@/utils/helpers';
import Component, { mixins } from 'vue-class-component';
import { Getter, namespace } from 'vuex-class';
import moment from 'moment';
import {
  IAPIKeys,
  IDashboardCount,
  ILogsCount,
  IScheduledEvent,
  ISenderID,
  IUser,
} from '@/types/types';
import { Watch } from 'vue-property-decorator';
const authModule = namespace('auth');
const apiKeyModule = namespace('apiKeys');
const reportsModule = namespace('reports');
const smsModule = namespace('sms');
const eventsModule = namespace('events');
@Component({
  name: 'Dashboard',
  components: {
    InternetConnection: loadWidget('pages/InternetConnection'),
    ToolBar: loadWidget('widgets/ToolBar'),
    SnackBar: loadWidget('widgets/SnackBar'),
    EmptyPage: loadWidget('pages/EmptyPage'),
    ProgressBar: loadWidget('widgets/CircularProgressLoader'),
    AddSMSSenderID: loadWidget(`widgets/AddSenderID`),
  },
  filters: {
    timestamp(value: string, format?: string) {
      return moment(value).format(format ?? 'Do MMMM, YYYY');
    },
    truncateContent,
  },
})
export default class Dashboard extends mixins(WidgetMixins, DialogMixins) {
  @Getter('getCreditBalance') creditBalance!: number;
  @apiKeyModule.Getter('getAPIKeys') accessKeys!: IAPIKeys;
  @reportsModule.Getter('getDashboardCount') count!: IDashboardCount;
  @reportsModule.Getter('getDailyDeliveryStats') dailyReports!: Array<{
    key: string;
    logs: ILogsCount;
  }>;
  @smsModule.Getter('getPendingSenderIDList') smsIDList!: Array<
    ISenderID & { uuid: string; id: number; slug: string }
  >;

  @authModule.Getter('getUserDetails') user!: IUser;
  @eventsModule.Getter('getAllEvents') events!: Array<
    IScheduledEvent & { start: string }
  >;

  date = [];
  menu = false;
  query = '';

  picker = '';
  minDate = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chartOptions: any = {
    chart: {
      type: 'bar',
      height: 600,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '18%',
        endingShape: 'rounded',
      },
    },
    colors: ['#01db01', '#e20639'],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 8,
      colors: ['transparent'],
    },
    xaxis: {
      min: 10,
      categories: [],
      label: {
        rotate: -180,
      },
    },
    yaxis: {
      title: {
        text: 'Messages Count ',
      },
      min: 1,
    },
    fill: {
      opacity: 1,
    },
  };

  series: Array<{ name: string; data: number[] }> = [];
  eventsCalendar: string[] = [];
  upcomingEvents: Array<IScheduledEvent & { start: string }> = [];

  @Watch('events')
  onScheduledEventsChange(
    value: Array<IScheduledEvent & { start: string }>
  ): void {
    this.eventsCalendar = value.map((event) =>
      moment(event.start).format('YYYY-MM-DD')
    );
    this.upcomingEvents = value
      .filter((event) => event.state === 'SCHEDULED')
      .slice(0, 6);
  }

  @Watch('dailyReports')
  onDailyReportsChange(
    dailyReports: Array<{ key: string; logs: ILogsCount }>
  ): void {
    const categories = dailyReports.map((report) => report.key);
    this.chartOptions = {
      ...this.chartOptions,
      xaxis: {
        categories:
          categories.length > 0
            ? categories
            : [
                ...new Array(7)
                  .fill(0)
                  .map((item, index) =>
                    moment().add(index, 'day').format('YYYY-MM-DD')
                  ),
              ],
      },
    };
    this.series = [
      {
        name: 'Total Delivered Count',
        data: dailyReports.map((report) => report.logs.delivered),
      },
      {
        name: 'Total Undelivered Count',
        data: dailyReports.map((report) => report.logs.undelivered),
      },
    ];
  }

  filterByDates(): void {
    (this.$refs.menu as HTMLElement & { save: (date: string[]) => void }).save(
      this.date
    );
    if (this.date.length > 0) {
      const [startDate, endDate] = this.date;
      this.query = `?startDate=${startDate}&endDate=${endDate}`;
      this.$store.dispatch('reports/dailyDeliveryStats', this.query);
      return;
    }
    this.query = '';
    this.$store.dispatch('reports/dailyDeliveryStats');
  }

  refreshPage(): void {
    this.$store.dispatch('reports/dailyDeliveryStats', this.query);
  }
  created(): void {
    this.minDate = moment().subtract(3, 'month').format('YYYY-MM-DD');
    this.$store.dispatch('reports/dashboardCount');
    this.$store.dispatch('reports/dailyDeliveryStats');
    this.picker = moment().format('YYYY-MM-DD');
    this.$store.dispatch('sms/pendingSenderID');
    this.$store.dispatch('events/list', {
      query: `?activeDate=${moment().format('YYYY-MM-DD')}&isPaginated=false`,
    });
  }
}
